<script setup lang="ts">
defineComponent({
  name: 'HeroComponent'
})
</script>

<template>
  <div class="d-flex ma-0 pa-0">
    <div class="hero-video">
      <video
        width="100%"
        height="auto"
        autoplay
        muted
        loop
        playsinline
        src="/videos/header.mp4"
        type="video/mp4"
      ></video>
      <div class="text-overlay pa-5 pa-md-12 rounded-ts-lg">
        <h1 class="text-h4 text-md-h1 underline">Wir digitalisieren Ihr Unternehmen</h1>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.hero-video {
  position: relative;
  width: 100%;
  overflow: hidden;

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.text-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
}

.underline {
  text-decoration: underline;
  text-decoration-color: #fca311;
  text-underline-offset: 4px;
  text-decoration-thickness: 3px;
}
</style>

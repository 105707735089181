<script setup lang="ts">
const leistungen = [
  {
    icon: 'IconApps',
    title: 'Mobile Apps & Cross-Plattform',
    text: 'Nativ oder Cross-Plattform? Wir entwickeln Ihre App für iOS und Android.',
    link: '/leistungen/mobile-apps'
  },
  {
    icon: 'IconWorldWww',
    title: 'Webentwicklung',
    text: 'Wir entwickeln Webseiten und Webanwendungen, die auf Ihre Bedürfnisse zugeschnitten sind.',
    link: '/leistungen/webentwicklung'
  },
  {
    icon: 'IconApi',
    title: 'Schnittstellen',
    text: 'Wir entwickeln leistungsfähige Schnittstellen und Middleware, die Ihre Systeme miteinander verbinden.',
    link: '/leistungen/apis'
  },
  {
    icon: 'IconAutomation',
    title: 'Prozessautomatisierung',
    text: 'Sie machen immer wieder die gleichen Schritte? Wir automatisieren Ihre Prozesse. Von Workflow-Management bis zur Integration von KI.',
    link: '/leistungen/prozessautomatisierung'
  },
  {
    icon: 'IconProgressCheck',
    title: 'Proof of Concept (PoC) & MVP',
    text: 'Sie haben eine Idee und möchten sie schnell umsetzen? Wir entwickeln Prototypen und MVPs.',
    link: '/leistungen/poc-mvp'
  },
  {
    icon: 'IconPuzzle',
    title: 'Individualsoftware',
    text: 'Sie benötigen eine individuelle Softwarelösung? Wir entwickeln Software, die auf Ihre Bedürfnisse zugeschnitten ist.',
    link: '/leistungen/individualsoftware'
  }
]

const evenServices = computed(() => leistungen.filter((_, index) => index % 2 === 0))
const oddServices = computed(() => leistungen.filter((_, index) => index % 2 !== 0))

defineComponent({
  name: 'LeistungenComponent'
})
</script>

<template>
  <div class="mt-100">
    <v-row no-gutters>
      <v-col cols="12" md="4">
        <div class="px-6 px-lg-0 sticky">
          <!-- <svg class="d-inline stroke-blue" width="80px" height="22px">
            <path d="M11 11 L60 11" stroke-linecap="round" stroke-width="20" />
          </svg> -->
          <h2 class="text-h2 pb-3">Unsere Leistungen</h2>
          <span class="text-body-1">
            Sie benötigen Software. Wir entwickeln Software. <br />
            Sie benötigen Beratung. Wir beraten Sie.
          </span>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="mt-16 mt-md-0" align="center">
        <template v-for="(service, index) of evenServices" :key="index">
          <LeistungenCard
            class="mb-16"
            :icon="service.icon"
            :title="service.title"
            :text="service.text"
            :link="service.link"
          />
        </template>
      </v-col>
      <v-col cols="12" md="4" class="mt-md-180" align="center">
        <template v-for="(service, index) of oddServices" :key="index">
          <LeistungenCard
            class="mb-16"
            :icon="service.icon"
            :title="service.title"
            :text="service.text"
            :link="service.link"
          />
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
.sticky {
  position: sticky;
}

.stroke-blue {
  stroke: #14213d;
}

.mt-md-80 {
  margin-top: inherit;
}

@media (min-width: 960px) {
  .mt-md-180 {
    margin-top: 180px;
  }
}
</style>

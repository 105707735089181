<script setup lang="ts">
defineComponent({
  name: 'IndexPage'
})

useSeoMeta({
  title: 'dreißigelf',
  articleModifiedTime: '2024-08-08'
})
</script>

<template>
  <div>
    <Hero />
    <Leistungen />
    <Fragen />
    <TechStack class="pb-16" />
  </div>
</template>
